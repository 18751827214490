import React, { useState } from "react";
import "./BGCFORM.css";
import Swal from "sweetalert2";
import imageCompression from "browser-image-compression";
import img from "../assets/images/Spinner-3.gif";

export const NewBgc1 = () => {
  const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  /* aplicant_id  */
  const [aplicant_id, setApplicant_Id] = useState("");

  /* first form field */

  const [name, setName] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [enumber, setEnumber] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [language, setLanguage] = useState("");
  const [blood, setBlood] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [post, setPost] = useState("");
  const [police, setPolice] = useState("");
  const [district, setDistrict] = useState("");
  const [tehsil, setTehsil] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");

  const [address11, setAddress11] = useState("");
  const [address22, setAddress22] = useState("");
  const [address33, setAddress33] = useState("");
  const [post1, setPost1] = useState("");
  const [police1, setPolice1] = useState("");
  const [district1, setDistrict1] = useState("");
  const [tehsil1, setTehsil1] = useState("");
  const [landmark1, setLandmark1] = useState("");
  const [pincode1, setPincode1] = useState("");
  const [state1, setState1] = useState("");

  /*second form ...... */
  const [institute, setInstitute] = useState("");
  const [qualification, setQualification] = useState("");
  const [from_edu, setFrom_Edu] = useState("");
  const [grade, setGrade] = useState("");
  const [city, setCity] = useState("");
  const [graduation, setGraduation] = useState("");
  const [to_edu, setTo_Edu] = useState("");

  const [marksheet10, setMarksheet10] = useState(null);
  const [marksheet12, setMarksheet12] = useState(null);
  const [graduation_marksheet, setGraduation_Marksheet] = useState(null);
  const [other_document1, setOther_Document1] = useState(null);
  const [provisional_degree, setProvisional_Degree] = useState(null);

  /* third form field */
  const [reference_1_name, setReference_1_Name] = useState("");
  const [reference_1_relation, setReference_1_Relation] = useState("");
  const [reference_1_address, setReference_1_Address] = useState("");
  const [reference_1_number, setReference_1_Number] = useState("");

  const [reference_2_name, setReference_2_Name] = useState("");
  const [reference_2_relation, setReference_2_Relation] = useState("");
  const [reference_2_address, setReference_2_Address] = useState("");
  const [reference_2_number, setReference_2_Number] = useState("");

  const [reference_3_name, setReference_3_Name] = useState("");
  const [reference_3_relation, setReference_3_Relation] = useState("");
  const [reference_3_address, setReference_3_Address] = useState("");
  const [reference_3_number, setReference_3_Number] = useState("");

  const [reference_4_name, setReference_4_Name] = useState("");
  const [reference_4_relation, setReference_4_Relation] = useState("");
  const [reference_4_address, setReference_4_Address] = useState("");
  const [reference_4_number, setReference_4_Number] = useState("");

  /* forth form field */
  const [aadhar, setAadhar] = useState("");
  const [aadhar_front_side, setAadhar_Front_Side] = useState("");
  const [aadhar_back_side, setAadhar_Back_Side] = useState("");
  const [passport_size_photo, setPassport_Size_Photo] = useState("");
  const [pan_card_number, setPan_Card_Number] = useState("");
  const [pan_card, setPan_Card] = useState("");
  const [voter_id_card, setVoter_Id_Card] = useState("");
  const [driving_license, setDriving_License] = useState("");
  const [passport, setPassport] = useState("");
  const [electricity_bill, setElectricity_Bill] = useState("");
  const [other_document, setOther_Document] = useState("");
  const [candidate, setCandidateId] = useState("");

  const billingFunction = () => {
    setIsSameAddressChecked((prevValue) => !prevValue);
    if (isSameAddressChecked) {
      setAddress11("");
      setAddress22("");
      setAddress33("");
      setPost1("");
      setPolice1("");
      setDistrict1("");
      setTehsil1("");
      setLandmark1("");
      setPincode1("");
      setState1("");
    } else {
      setAddress11(address1);
      setAddress22(address2);
      setAddress33(address3);
      setPost1(post);
      setPolice1(police);
      setDistrict1(district);
      setTehsil1(tehsil);
      setLandmark1(landmark);
      setPincode1(pincode);
      setState1(state);
    }
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState([
    {
      previous_company_name: "",
      previous_designation: "",

      hr_name: "",
      Hr_number: "",
      Hr_email_id: "",

      Employee_id: "",
      previous_salary: "",

      reason_of_leaving: "",
      previous_manager: "",

      previous_manager_number: "",
      previous_manager_email_id: "",

      from_previous_company: "",
      to_previous_company: "",

      location: "",
      work_reponsibility: "",

      payslip: "",
      pay_slip2: "",
      pay_slip3: "",

      offer_letter: "",
      resignation: "",
      experience_letter: "",
    },
  ]);

  /*first page next......... */
  const nextStep1 = () => {
    if (
      name &&
      fname &&
      mname &&
      gender &&
      date &&
      email &&
      number &&
      enumber &&
      companyname &&
      location &&
      address1 &&
      post &&
      police &&
      district &&
      tehsil &&
      landmark &&
      pincode &&
      state &&
      address11 &&
      post1 &&
      police1 &&
      district1 &&
      tehsil1 &&
      landmark1 &&
      pincode1 &&
      state1
    ) {
      const emailR =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailR.test(email)) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        alert("Please enter a valid email address");
      }
    } else {
      alert("Please fill out all required fields");
    }
  };

  /*Second page next......... */
  const nextStep2 = () => {
    if (
      institute &&
      qualification &&
      from_edu &&
      to_edu &&
      grade &&
      city &&
      marksheet10 &&
      marksheet12 &&
      graduation &&
      graduation_marksheet &&
      provisional_degree
    ) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      alert("Please fill out all required fields");
    }
  };

  /*Third page next......... */
  const nextStep3 = () => {
    if (
      reference_1_name &&
      reference_1_relation &&
      reference_1_number &&
      reference_1_address &&
      reference_2_name &&
      reference_2_relation &&
      reference_2_number &&
      reference_2_address &&
      reference_3_name &&
      reference_3_relation &&
      reference_3_number &&
      reference_3_address &&
      reference_4_name &&
      reference_4_relation &&
      reference_4_number &&
      reference_4_address
    ) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      alert("Please fill out all required fields");
    }
  };

  /*Fourth page next......... */
  const nextStep4 = () => {
    if (
      aadhar &&
      aadhar_front_side &&
      aadhar_back_side &&
      passport_size_photo
    ) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      alert("Please fill out all required fields");
    }
  };

  /*fifth page form.... */
  const nextStep5 = () => {
    if (hideTenancy) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      alert("Please fill out all required fields");
    }
  };

  const nextStep6 = () => {
    setCurrentStep((prevStep) => prevStep + 5);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  /*Fifth form field...... */

  /*Sixth form... */
  const [tenancy_number, setTenancy_Number] = useState("");
  const [house_owner_name, setHouse_Owner_Name] = useState("");
  const [tenancy_address, setTenancy_Address] = useState("");
  const [residing_date, setResiding_date] = useState("");
  // const [addformfiledemployment, setaddformfiledemployment] = useState();
  const [selectExperience, setSelectExperience] = useState(false);
  const [fresher, setFresher] = useState(false);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormValues = [...formValues];
    updatedFormValues[index][name] = value;
    setFormValues(updatedFormValues);
  };

  
  const handleFileChange1 = async (event, index, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileType = file.type;

        if (
          fileType === "application/pdf" ||
          fileType === "image/jpeg" ||
          fileType === "image/png"
        ) {
          let fileToSet = file;
          if (fileType !== "application/pdf") {
            const options = {
              maxSizeMB: 0.2,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            fileToSet = await imageCompression(file, options);
          }
          const updatedFormValues = [...formValues];
          updatedFormValues[index][fieldName] = fileToSet;
          setFormValues(updatedFormValues);
        } else {
          console.error(
            "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
          );
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };


  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        previous_company_name: "",
        previous_designation: "",

        hr_name: "",
        Hr_number: "",
        Hr_email_id: "",

        Employee_id: "",
        previous_salary: "",

        reason_of_leaving: "",
        previous_manager: "",

        previous_manager_number: "",
        previous_manager_email_id: "",

        from_previous_company: "",
        to_previous_company: "",

        location: "",
        work_reponsibility: "",

        payslip: "",
        pay_slip2: "",
        pay_slip3: "",

        offer_letter: "",
        resignation: "",
        experience_letter: "",
      },
    ]);
  };

  const [isOwnHouse, setIsOwnHouse] = useState(false);

  const hideTenancy = () => {
    const selected = document.getElementById("tenancy1").value;
    setIsOwnHouse(selected === "Rented");
  };

  const EmploymentSubmit = async (e) => {
    setIsLoading(true);
    console.log("hii");
    e.preventDefault();
       const isAnyFieldEmpty = formValues.some((form) =>
      Object.entries(form).some(([key, value]) => {
        if (typeof value === "string" && value.trim() === "") {
          return true; 
        }

        if (key.includes("email") && value.trim() !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            alert("Please enter a valid email address");
            return true; 
          }
        }
    
        return false;
      })
    );
    
    if (isAnyFieldEmpty) {
      alert("Please fill all required fields");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formValues.forEach((form, index) => {
      formData.append(`previous_company_name`, form.previous_company_name);
      formData.append(`previous_designation`, form.previous_designation);

      formData.append(`hr_name`, form.hr_name);
      formData.append(`Hr_number`, form.Hr_number);
      formData.append(`Hr_email_id`, form.Hr_email_id);

      formData.append(`Employee_id`, form.Employee_id);
      formData.append(`previous_salary`, form.previous_salary);

      formData.append(`reason_of_leaving`, form.reason_of_leaving);
      formData.append(`previous_manager`, form.previous_manager);

      formData.append(`previous_manager_number`, form.previous_manager_number);
      formData.append(
        `previous_manager_email_id`,
        form.previous_manager_email_id
      );

      formData.append(`from_previous_company`, form.from_previous_company);
      formData.append(`to_previous_company`, form.to_previous_company);

      formData.append(`location`, form.location);
      formData.append(`work_reponsibility`, form.work_reponsibility);
      // console.log(form.length,"hii")

      formData.append(`payslip`, form.payslip);
      formData.append(`pay_slip2`, form.pay_slip2);
      formData.append(`pay_slip3`, form.pay_slip3);

      formData.append(`offer_letter`, form.offer_letter);
      formData.append(`resignation`, form.resignation);
      formData.append(`experience_letter`, form.experience_letter);
    });
    formData.append("candidate", candidate);
    formData.append("fresher", fresher);

    let response = await fetch(
      "https://humhai.in/background-verification/employment",
      {
        method: "POST",
        // headers: {
        //   // "Content-Type": "multipart/form-data",
        //   'Content-Type':' image/jpeg',
        // },
        body: formData,
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    // console.log("response");
    let data = await response.json();
    // console.log(data, "data");
    // console.log(response.status, "status");
    if (data.status === 200) {
      addFormFields();
      setIsLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
      });
      setIsLoading(false);
    }
  };

  const EmploymentSubmit1 = async (e) => {
    setIsLoading(true);
        const isAnyFieldEmpty = formValues.some((form) =>
      Object.entries(form).some(([key, value]) => {
        if (typeof value === "string" && value.trim() === "") {
          return true; 
        }

        if (key.includes("email") && value.trim() !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            alert("Please enter a valid email address");
            return true; 
          }
        }
    
        return false;
      })
    );
    
    if (isAnyFieldEmpty) {
      alert("Please fill all required fields");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formValues.forEach((form, index) => {
      formData.append(`previous_company_name`, form.previous_company_name);
      formData.append(`previous_designation`, form.previous_designation);

      formData.append(`hr_name`, form.hr_name);
      formData.append(`Hr_number`, form.Hr_number);
      formData.append(`Hr_email_id`, form.Hr_email_id);

      formData.append(`Employee_id`, form.Employee_id);
      formData.append(`previous_salary`, form.previous_salary);

      formData.append(`reason_of_leaving`, form.reason_of_leaving);
      formData.append(`previous_manager`, form.previous_manager);

      formData.append(`previous_manager_number`, form.previous_manager_number);
      formData.append(
        `previous_manager_email_id`,
        form.previous_manager_email_id
      );

      formData.append(`from_previous_company`, form.from_previous_company);
      formData.append(`to_previous_company`, form.to_previous_company);

      formData.append(`location`, form.location);
      formData.append(`work_reponsibility`, form.work_reponsibility);

      formData.append(`payslip`, form.payslip);
      formData.append(`pay_slip2`, form.pay_slip2);
      formData.append(`pay_slip3`, form.pay_slip3);

      formData.append(`offer_letter`, form.offer_letter);
      formData.append(`resignation`, form.resignation);
      formData.append(`experience_letter`, form.experience_letter);
    });

    formData.append("candidate", candidate);
    formData.append("fresher", fresher);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/employment",
        {
          method: "POST",
          body: formData,
        }
      );
      let data = await response.json();
      // console.log(data,"data")

      if (response.ok) {
        // alert(
        //   `name : ${data.name} mobile no.: ${data.mobile} reference Id : ${data.reference_id}  submitted as Experience`
        // );
        alert(`Your form has been successfully submitted. A reference ID will be provided once your data has been checked.`);
        setIsLoading(false);
        clearValue();
        window.location.reload();
      } else {
        throw new Error(data.message || "Failed to submit data");
      }
    } catch (error) {
      // console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error.message || "Failed to submit data",
      });
      setIsLoading(false);
    }
  };

  const BgcFormSubmit = async (e) => {
    setIsLoading(true);
    // e.preventDefault();
    const formData = new FormData();

    // formData.append('employmentdata', employmentdata);
    formData.append("full_name", name);
    formData.append("fathers_name", fname);
    formData.append("mothers_name", mname);
    formData.append("gender", gender);
    formData.append("dob", date);
    formData.append("email", email);
    formData.append("contact_number", number);
    formData.append("emergency_number", enumber);
    formData.append("name_of_the_client", companyname);
    formData.append("client_location", location);
    formData.append("language_known", language);
    formData.append("blood_group", blood);
    formData.append("current_village_city", address1);
    formData.append("current_village_city2", address2);
    formData.append("current_village_city3", address3);
    formData.append("current_post_office", post);
    formData.append("current_police_station", police);
    formData.append("current_district", district);
    formData.append("current_tehsil", tehsil);
    formData.append("current_landmark", landmark);
    formData.append("current_pincode", pincode);
    formData.append("current_state", state);
    formData.append("permanent_village_city", address11);
    formData.append("permanent_village_city2", address22);
    formData.append("permanent_village_city3", address33);
    formData.append("permanent_post_office", post1);
    formData.append("permanent_police_station", police1);
    formData.append("permanent_district", district1);
    formData.append("permanent_tehsil", tehsil1);
    formData.append("permanent_landmark", landmark1);
    formData.append("permanent_pincode", pincode1);
    formData.append("permanent_state", state1);
    formData.append("institute_name", institute);
    formData.append("qualification", qualification);
    formData.append("from_edu", from_edu);
    formData.append("to_edu", to_edu);
    formData.append("grade_edu", grade);
    formData.append("city_edu", city);
    formData.append("marksheet_10", marksheet10);
    formData.append("marksheet_12", marksheet12);
    formData.append("higher_education", graduation);
    formData.append("higher_education_marksheet", graduation_marksheet);
    formData.append("higher_education_degree_provisional", provisional_degree);
    formData.append("ref_name1", reference_1_name);
    formData.append("ref_relation1", reference_1_relation);
    formData.append("ref_contact1", reference_1_number);
    formData.append("ref_address1", reference_1_address);
    formData.append("ref_name2", reference_2_name);
    formData.append("ref_relation2", reference_2_relation);
    formData.append("ref_contact2", reference_2_number);
    formData.append("ref_address2", reference_2_address);
    formData.append("ref_name3", reference_3_name);
    formData.append("ref_relation3", reference_3_relation);
    formData.append("ref_contact3", reference_3_number);
    formData.append("ref_address3", reference_3_address);
    formData.append("ref_name4", reference_4_name);
    formData.append("ref_relation4", reference_4_relation);
    formData.append("ref_contact4", reference_4_number);
    formData.append("ref_address4", reference_4_address);
    formData.append("adhar_number", aadhar);
    formData.append("adhar_frontside", aadhar_front_side);
    formData.append("adhar_backside", aadhar_back_side);
    formData.append("photo", passport_size_photo);
    formData.append("pancard_number", pan_card_number);
    formData.append("pan_card", pan_card);
    formData.append("voter_di", voter_id_card);
    formData.append("driving_license", driving_license);
    formData.append("passport", passport);
    formData.append("Telephone_electricity", electricity_bill);
    formData.append("other_document", other_document);
    // formData.append("employementdata", employementdata);
    formData.append("house_owner_name", house_owner_name);
    formData.append("tenancy_contact", tenancy_number);
    formData.append("address", tenancy_address);
    formData.append("residing_date", residing_date);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/featured-form/",
        {
          method: "POST",
          body: formData,
        }
      );

      let data = await response.json();
      // console.log(data, "data");
      setCandidateId(data.id);
      // console.log(candidate, "candidate");

      if (response.status === 200) {
        // console.log("data.status", data.status);
        setIsLoading(false);
        // alert(`text: Copy/ScreenShot - Your HH ID: ${data.candidate}`);
        alert(`Your form has been successfully submitted. A reference ID will be provided once your data has been checked.`);
        nextStep5();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearValue = () => {
    setName("");
    setFname("");
    setMname("");
    setGender("");
    setDate("");
    setEmail("");
    setNumber("");
    setEnumber("");
    setCompanyName("");
    setLocation("");
    setLanguage("");
    setBlood("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setPost("");
    setPolice("");
    setDistrict("");
    setTehsil("");
    setLandmark("");
    setPincode("");
    setState("");
    setAddress11("");
    setAddress22("");
    setAddress33("");
    setPost1("");
    setPolice1("");
    setDistrict1("");
    setTehsil1("");
    setLandmark1("");
    setPincode1("");
    setState1("");
    setInstitute("");
    setQualification("");
    setFrom_Edu("");
    setGrade("");
    setCity("");
    setGraduation("");
    setTo_Edu("");
    setMarksheet10("");
    setMarksheet12("");
    setGraduation_Marksheet("");
    setOther_Document1("");
    setProvisional_Degree("");
    setReference_1_Name("");
    setReference_1_Relation("");
    setReference_1_Address("");
    setReference_1_Number("");
    setReference_2_Name("");
    setReference_2_Relation("");
    setReference_2_Address("");
    setReference_2_Number("");
    setReference_3_Name("");
    setReference_3_Relation("");
    setReference_3_Address("");
    setReference_3_Number("");
    setReference_4_Name("");
    setReference_4_Relation("");
    setReference_4_Address("");
    setReference_4_Number("");
    setAadhar("");
    setAadhar_Front_Side("");
    setAadhar_Back_Side("");
    setPassport_Size_Photo("");
    setPan_Card_Number("");
    setPan_Card("");
    setVoter_Id_Card("");
    setDriving_License("");
    setPassport("");
    setElectricity_Bill("");
    setOther_Document("");
    // setEmployementData("")
    setTenancy_Number("");
    setHouse_Owner_Name("");
    setTenancy_Address("");
    setResiding_date("");
    setFormValues("");
  };

  const handleFileChange = async (event, setStateFunction) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileType = file.type;

        if (
          fileType === "application/pdf" ||
          fileType === "image/jpeg" ||
          fileType === "image/png"
        ) {
          if (fileType === "application/pdf") {
            setStateFunction(file);
          } else {
            const options = {
              maxSizeMB: 0.2,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
            setStateFunction(compressedFile);
          }
        } else {
          console.error(
            "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
          );
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };


  const checkAplicantId = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("reference_id", aplicant_id);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/featured-form/",
        {
          method: "POST",
          body: formData,
        }
      );
      let data = await response.json();
      setCandidateId(data.id);

      if (response.ok) {
        setIsLoading(false);
        nextStep6();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      // console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error.message,
      });
      setSuccess(false);
      setIsLoading(false);
    }
  };

  const EmploymentSubmit2 = async (fresherValue) => {
    const formData = new FormData();
    formData.append("fresher", fresherValue);
    formData.append("candidate", candidate);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/employment",
        {
          method: "POST",
          body: formData,
        }
      );
      let data = await response.json();
      // console.log(data.status,"data")

      if (response.ok) {
        // alert(
        //   `name : ${data.name || "Unknown"} mobile no.: ${
        //     data.mobile || "Unknown"
        //   } reference Id : ${
        //     data.reference_id || "Unknown"
        //   } submitted as fresher`
        // );
        alert(`Your form has been successfully submitted. A reference ID will be provided once your data has been checked.`);

        Swal.fire({
          icon: "success",
          title: response.message,
        });
        setSuccess(false);
        clearValue();
        window.location.reload();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      // console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error.message,
      });
      setSuccess(false);
    }
  };

  const hideEmployment = () => {
    const selected = document.getElementById("employment1").value;
    if (selected === "Experience") {
      setSelectExperience("Experience");
      setFresher(false);
    } else if (selected === "Fresher") {
      const confirmed = window.confirm(
        "Are You Sure You Want To Submit This Form As Fresher ?"
      );
      if (confirmed) {
        setFresher(true);
        EmploymentSubmit2(true);
      } else {
        console.log("Cancel clicked");
      }
    } else {
      setSelectExperience("");
    }
  };

  return (
    <div>
      <div id="regForm" encType="multipart/form-data">
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value="rXPcP9QunvYuPOuSr55cXHpozG0hvOovqlifTwz9TER1IFe6RbR7dIFp1PosY8N8"
        />
        <h1>Background Verification</h1>
        {/* First Form ...... */}
        {currentStep === 1 && (
          <div>
            <div className="personal">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  PERSONAL
                </span>{" "}
                DETAILS
              </div>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <p style={{ margin: "10px" }}>
                  If you have already submitted your HH ID, please enter your ID
                  to proceed with the further process.{" "}
                </p>
                <div className="row ms-4 mb-4">
                  <label htmlFor="name_of_applicant" className="form-label">
                    Applicant HH Id (Optional):
                  </label>
                  <br />
                  <div className="col-md-6 d-flex align-items-end">
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="name_of_applicant_id"
                      value={aplicant_id}
                      onChange={(e) => setApplicant_Id(e.target.value)}
                      name="name_of_applicant_id"
                      maxLength="100"
                      required
                    />
                  </div>
                  <div className="col-md-6 ">
                    <button
                      type="button"
                      className="tablinks mb-2 ms-2"
                      onClick={checkAplicantId}
                    >
                      Next
                    </button>
                  </div>
                </div>
                <hr style={{ margin: "0px 10px 30px" }} />

                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="name_of_applicant" className="form-label">
                      Name of the Applicant:
                    </label>
                    <br />
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="recruiters"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name_of_applicant"
                      maxLength="100"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input2" className="form-label">
                      Fathers/Husband/Wife Name
                    </label>
                    <br />
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="projects"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                      name="fathers_name"
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input3" className="form-label">
                      Mother's Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={mname}
                      onChange={(e) => setMname(e.target.value)}
                      name="mothers_name"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input4" className="form-label">
                      Applicant Gender
                    </label>{" "}
                    <br />
                    <select
                      className="inputtype bg-light mb-5 form-control"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      name="gender"
                    >
                      <option value="">choose</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">other</option>
                    </select>
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 input-icons">
                    <label htmlFor="Input5" className="form-label">
                      Date Of Birth
                    </label>
                    <br />
                    <div className="date-container">
                      <input
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        type="date"
                        className="inputtype bg-light mb-5 form-control"
                        name="dob"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input6" className="form-label">
                      Email Id
                    </label>{" "}
                    <br />
                    <input
                      type="email"
                      className="inputtype bg-light mb-5 form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 input-icons">
                    <label htmlFor="Input5" className="form-label">
                      Contact Number
                    </label>
                    <br />
                    <div className="date-container">
                      <input
                        type="number"
                        className="inputtype bg-light mb-5 form-control"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        name="contact_number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input6" className="form-label">
                      Emergency Contact
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={enumber}
                      onChange={(e) => setEnumber(e.target.value)}
                      name="emergency_contact"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Company Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={companyname}
                      onChange={(e) => setCompanyName(e.target.value)}
                      name="name_of_client"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Company Location
                    </label>
                    <br />
                    <input
                      type="text"
                      className="bg-light mb-5 form-control"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      name="client_location"
                      maxLength="30"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Languages Known (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className="bg-light mb-5 form-control"
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                      name="languages"
                      maxLength="30"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Blood Group (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className="bg-light mb-5 form-control"
                      value={blood}
                      onChange={(e) => setBlood(e.target.value)}
                      name="blood_group"
                      maxLength="5"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <h2>Permanent Address</h2>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 1
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                      name="village_city"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 2 (optional upto 30 charector)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      name="village_city2"
                      maxLength="25"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 3 (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      value={address3}
                      onChange={(e) => setAddress3(e.target.value)}
                      name="village_city3"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Post Office
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={post}
                      onChange={(e) => setPost(e.target.value)}
                      name="post_office"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Police Station/Thana
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={police}
                      onChange={(e) => setPolice(e.target.value)}
                      name="police_station"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      District
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                      name="district"
                      maxLength="100"
                    />
                  </div>
                </div>

                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Tehsil
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={tehsil}
                      onChange={(e) => setTehsil(e.target.value)}
                      name="tehsil"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Landmark
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                      name="landmark"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Pin Code
                    </label>{" "}
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      name="pine_code"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      State
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      name="state"
                      maxLength="20"
                    />
                  </div>
                </div>

                <div className="row ms-4 mb-4 d-flex flex-row">
                  <label
                    style={{ width: "190px" }}
                    className="form-label"
                    htmlFor="same"
                  >
                    Same as above?
                  </label>
                  <input
                    style={{ width: "30px" }}
                    type="checkbox"
                    id="same"
                    name="same"
                    onChange={() => billingFunction()}
                  />
                </div>

                <div className="row ms-4 mb-4">
                  <h2>Current Address</h2>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 1
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={address11}
                      onChange={(e) => setAddress11(e.target.value)}
                      name="c_village_city"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 2(optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={address22}
                      onChange={(e) => setAddress22(e.target.value)}
                      name="c_village_city2"
                      maxLength="25"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 3 (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={address33}
                      onChange={(e) => setAddress33(e.target.value)}
                      name="c_village_city3"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Post Office
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={post1}
                      onChange={(e) => setPost1(e.target.value)}
                      name="c_post_office"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Police Station
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={police1}
                      onChange={(e) => setPolice1(e.target.value)}
                      name="c_police_station"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      District
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={district1}
                      onChange={(e) => setDistrict1(e.target.value)}
                      name="c_district"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Tehsil
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={tehsil1}
                      onChange={(e) => setTehsil1(e.target.value)}
                      name="c_tehsil"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Landmark
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={landmark1}
                      onChange={(e) => setLandmark1(e.target.value)}
                      name="c_landmark"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Pin Code
                    </label>{" "}
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={pincode1}
                      onChange={(e) => setPincode1(e.target.value)}
                      name="c_pine_code"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      State
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={state1}
                      onChange={(e) => setState1(e.target.value)}
                      name="c_state"
                      maxLength="20"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <div style={{ float: "right" }}>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    onClick={nextStep1}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Second Form ...... */}
        {currentStep === 2 && (
          <div>
            <div className="education bg-light rounded-3 p-2 mx-auto mb-3 border border-opacity-25">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  EDUCATION
                </span>{" "}
                DETAILS
              </div>
              <h4 className=" text-center mb-2">
                Please Remove All Password From The Documents
              </h4>
              <h5 className=" text-center mb-5">
                <a href="https://www.ilovepdf.com/unlock_pdf">
                  Click here to Remove Password
                </a>
              </h5>
              <hr />
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="organisation">Institute Name</label>
                  <br />
                  <input
                    type="text"
                    value={institute}
                    onChange={(e) => setInstitute(e.target.value)}
                    name="institute_name"
                    className=" bg-light mt-3 form-control"
                    maxLength="100"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="designation">Qualification</label>
                  <br />
                  <input
                    type="text"
                    value={qualification}
                    onChange={(e) => setQualification(e.target.value)}
                    name="qualification"
                    className=" bg-light mt-3 file-upload form-control"
                    maxLength="100"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="organisation">From</label>
                  <br />
                  <input
                    type="date"
                    value={from_edu}
                    onChange={(e) => setFrom_Edu(e.target.value)}
                    name="from_edu"
                    className=" bg-light mt-3 form-control"
                    //   value="None"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="designation">To</label>
                  <br />
                  <input
                    type="date"
                    value={to_edu}
                    onChange={(e) => setTo_Edu(e.target.value)}
                    name="to_edu"
                    className=" bg-light mt-3 file-upload form-control"
                    //   value="None"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="organisation">Grades</label>
                  <br />
                  <input
                    type="text"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    name="grade_edu"
                    className=" bg-light mt-3 form-control"
                    maxLength="10"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="City">City</label>
                  <br />
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    name="city_edu"
                    className=" bg-light mt-3 file-upload form-control"
                    maxLength="20"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="10th Marksheet">10th Marksheet</label>
                  <br />
                  <input
                    type="file"
                    // value={marksheet10}
                    onChange={(e) => handleFileChange(e, setMarksheet10)}
                    name="marksheet_10"
                    className=" bg-light mt-3 form-control"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="12th Marksheet">12th Marksheet</label>
                  <br />
                  <input
                    type="file"
                    // value={marksheet12}
                    onChange={(e) => handleFileChange(e, setMarksheet12)}
                    name="marksheet_12"
                    className=" bg-light mt-3 file-upload form-control"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="10th Marksheet">
                    Graduation/Post Graduation
                  </label>
                  <br />
                  <select
                    className="form-control mt-3"
                    name="higher_education"
                    value={graduation}
                    onChange={(e) => setGraduation(e.target.value)}
                  >
                    <option value="">Choose</option>
                    <option value="BA">BA</option>
                    <option value="B.COM">B.COM</option>
                    <option value="BSc">BSc</option>
                    <option value="MA">MA</option>
                    <option value="MBA">MBA</option>
                    <option value="B.TECH">B.TECH</option>
                    <option value="M.TECH">M.TECH</option>
                    <option value="M.COM">M.COM</option>
                    <option value="M.Sc">M.Sc</option>
                    <option value="other" id="otherprogram">
                      Other program
                    </option>
                  </select>
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="12th Marksheet">
                    Graduation/Post Graduation Marksheet
                  </label>
                  <br />
                  <input
                    type="file"
                    // value={graduation_marksheet}
                    onChange={(e) =>
                      handleFileChange(e, setGraduation_Marksheet)
                    }
                    name="higher_education_marksheet"
                    className=" bg-light mt-3 file-upload form-control"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div
                  id="othertext"
                  className="col-md-6 col-sm-12 mb-5"
                  style={{ display: "none" }}
                >
                  <label htmlFor="City">Type Other Program Name</label>
                  <br />
                  <input
                    type="text"
                    // value={other_document1}
                    onChange={(e) => handleFileChange(e, setOther_Document1)}
                    name="other_document1"
                    className=" bg-light mt-3 file-upload form-control"
                    maxLength="20"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="12th Marksheet">
                    Graduation/Post Graduation Degree/Provisional Degree
                  </label>
                  <br />
                  <input
                    type="file"
                    // value={provisional_degree}
                    onChange={(e) => handleFileChange(e, setProvisional_Degree)}
                    name="higher_education_degree_provisional"
                    className=" bg-light mt-3 file-upload form-control"
                  />
                </div>
              </div>
            </div>
            <div style={{ overflow: "auto" }}>
              <div style={{ float: "right" }}>
                <button
                  type="button"
                  style={{ backgroundColor: "#BBBBBB" }}
                  className="tablinks mb-2 ms-2"
                  onClick={prevStep}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="tablinks mb-2 ms-2"
                  onClick={nextStep2}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Third Form ...... */}
        {currentStep === 3 && (
          <div>
            <div className="reference">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  REFERENCES
                </span>{" "}
                DETAILS
              </div>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <hr className="ms-5 me-5" />
                <div className="ref1 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 1</h4>
                  <h5>Give Reference of your Family or Relatives ONLY</h5>
                </div>
                <hr className="ms-5 me-5 mb-4" />

                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_name}
                      onChange={(e) => setReference_1_Name(e.target.value)}
                      name="ref_name1"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_relation}
                      onChange={(e) => setReference_1_Relation(e.target.value)}
                      name="ref_relation1"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_number}
                      onChange={(e) => setReference_1_Number(e.target.value)}
                      name="ref_conatact1"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_address}
                      onChange={(e) => setReference_1_Address(e.target.value)}
                      name="ref_address1"
                      maxLength="100"
                    />
                  </div>
                </div>

                <hr className="ms-5 me-5" />
                <div className="ref2 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 2</h4>
                  <h5>Give Reference of your Family or Relatives ONLY</h5>
                </div>
                <hr className="ms-5 me-5 mb-4" />
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_name}
                      onChange={(e) => setReference_2_Name(e.target.value)}
                      name="ref_name2"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_relation}
                      onChange={(e) => setReference_2_Relation(e.target.value)}
                      name="ref_relation2"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_number}
                      onChange={(e) => setReference_2_Number(e.target.value)}
                      name="ref_contact2"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_address}
                      onChange={(e) => setReference_2_Address(e.target.value)}
                      name="ref_address2"
                      maxLength="100"
                    />
                  </div>
                </div>
                <hr className="ms-5 me-5" />
                <div className="ref3 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 3</h4>
                  <h5>Give Reference of Your Friend Or Colleague Only</h5>
                </div>
                <hr className="ms-5 me-5 mb-4" />
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_name}
                      onChange={(e) => setReference_3_Name(e.target.value)}
                      name="ref_name3"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_relation}
                      onChange={(e) => setReference_3_Relation(e.target.value)}
                      name="ref_relation3"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_number}
                      onChange={(e) => setReference_3_Number(e.target.value)}
                      name="ref_contact3"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_address}
                      onChange={(e) => setReference_3_Address(e.target.value)}
                      name="ref_address3"
                      maxLength="100"
                    />
                  </div>
                </div>
                <hr className="ms-5 me-5" />
                <div className="ref4 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 4</h4>
                  <h5>Give Reference of Your Friend Or Colleague Only</h5>
                </div>
                <hr className="ms-5 me-5 mb-4" />
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_name}
                      onChange={(e) => setReference_4_Name(e.target.value)}
                      name="ref_name4"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_relation}
                      onChange={(e) => setReference_4_Relation(e.target.value)}
                      name="ref_relation4"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_number}
                      onChange={(e) => setReference_4_Number(e.target.value)}
                      name="ref_contact4"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_address}
                      onChange={(e) => setReference_4_Address(e.target.value)}
                      name="ref_address4"
                      maxLength="100"
                    />
                  </div>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <div style={{ float: "right" }}>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    style={{ backgroundColor: "#BBBBBB" }}
                    onClick={prevStep}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    onClick={nextStep3}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Forth Form ...... */}
        {currentStep === 4 && (
          <div>
            <div className="document">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  DOCUMENTS{" "}
                </span>{" "}
                DETAILS
              </div>
              <h4 className=" text-center mb-2">
                Please Remove All Password From The Documents
              </h4>
              <h5 className=" text-center mb-5">
                <a href="https://www.ilovepdf.com/unlock_pdf">
                  Click here to Remove Password
                </a>
              </h5>
              <h3 className=" text-center" style={{color:"rgb(255, 119, 0)"}}>* Upload a Fully Clear and Completed Document *</h3>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <div className="row ms-4 mb-5">
                  <div className="col-md-4">
                    <label htmlFor="Input7" className="form-label">
                      Aadhar Card Number
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={aadhar}
                      onChange={(e) => setAadhar(e.target.value)}
                      name="aadhar_card"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input8" className="form-label">
                      Aadhar Card FRONT SIDE
                    </label>
                    <br />
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      // value={aadhar_front_side}
                      onChange={(e) =>
                        handleFileChange(e, setAadhar_Front_Side)
                      }
                      name="aadhar_front"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Aadhar Card BACK SIDE
                    </label>
                    <br />
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      // value={aadhar_back_side}
                      onChange={(e) => handleFileChange(e, setAadhar_Back_Side)}
                      name="aadhar_back"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-5">
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Passport Size Photo
                    </label>
                    <br />
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      // value={passport_size_photo}
                      onChange={(e) =>
                        handleFileChange(e, setPassport_Size_Photo)
                      }
                      name="passport_photo"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input8" className="form-label">
                      Pan Card Number (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      value={pan_card_number}
                      onChange={(e) => setPan_Card_Number(e.target.value)}
                      name="pan_number"
                    />
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Pan Card (optional)
                    </label>{" "}
                    <br />
                    <input
                      type="file"
                      className=" bg-light mb-5 form-control file-upload"
                      // value={pan_card}
                      onChange={(e) => handleFileChange(e, setPan_Card)}
                      name="pan_card"
                    />
                  </div>
                </div>

                <div className="row ms-4 mb-4">
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Voter ID Card (optional)
                    </label>
                    <br />
                    <input
                      type="file"
                      className=" bg-light mb-5 form-control file-upload"
                      // value={voter_id_card}
                      onChange={(e) => handleFileChange(e, setVoter_Id_Card)}
                      name="voter_card"
                    />
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Driving License (optional)
                    </label>
                    <br />
                    <input
                      type="file"
                      className=" bg-light mb-5 form-control file-upload"
                      // value={driving_license}
                      onChange={(e) => handleFileChange(e, setDriving_License)}
                      name="driving_license"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Passport (optional)
                    </label>{" "}
                    <br />
                    <input
                      type="file"
                      className=" bg-light mb-5 form-control file-upload"
                      // value={passport}
                      onChange={(e) => handleFileChange(e, setPassport)}
                      name="passport"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Electricity Bill/Telephone Bill (optional)
                    </label>
                    <br />
                    <input
                      type="file"
                      className=" bg-light mb-5 form-control file-upload"
                      // value={electricity_bill}
                      onChange={(e) => handleFileChange(e, setElectricity_Bill)}
                      name="Electricity_bill_Telephone_bill"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Other Document (optional)
                    </label>
                    <br />
                    <input
                      type="file"
                      className=" bg-light mb-5 form-control file-upload"
                      // value={other_document}
                      onChange={(e) => handleFileChange(e, setOther_Document)}
                      name="other_document"
                    />
                  </div>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <div style={{ float: "right" }}>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    style={{ backgroundColor: "#BBBBBB" }}
                    onClick={prevStep}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    onClick={nextStep4}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Fifth Form ...... */}
        {currentStep === 5 && (
          <div>
            <div className="tenancy">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}> TENANCY</span>{" "}
                DETAILS
              </div>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 col-sm-12 mb-5">
                    <label>Tenancy</label>
                    <br />
                    <select
                      className="form-control mt-3"
                      name="choose_tenancy"
                      id="tenancy1"
                      onChange={() => {
                        hideTenancy();
                        setHouse_Owner_Name("");
                        setTenancy_Address("");
                        setTenancy_Number("");
                        setResiding_date("");
                      }}
                    >
                      <option value="">Choose</option>
                      <option value="Own House">Own House</option>
                      <option value="Rented">Rented</option>
                    </select>
                  </div>
                </div>
                {isOwnHouse ? (
                  <div>
                    <div className="row ms-4 mb-4">
                      <div className="col-md-6">
                        <label className="form-label">House Owner Name</label>
                        <br />
                        <input
                          type="text"
                          className="inputtype bg-light mb-5 form-control"
                          value={house_owner_name}
                          onChange={(e) => setHouse_Owner_Name(e.target.value)}
                          name="house_owner_name"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Address</label> <br />
                        <input
                          type="text"
                          className="inputtype bg-light mb-5 form-control"
                          value={tenancy_address}
                          onChange={(e) => setTenancy_Address(e.target.value)}
                          name="tenancy_address"
                        />
                      </div>
                    </div>
                    <div className="row ms-4 mb-4">
                      <div className="col-md-6">
                        <label className="form-label">Contact Number</label>
                        <br />
                        <input
                          type="number"
                          className="inputtype bg-light mb-5 form-control"
                          value={tenancy_number}
                          onChange={(e) => setTenancy_Number(e.target.value)}
                          name="tenancy_number"
                        />
                      </div>
                      <div className="col-md-6 input-icons">
                        <label className="form-label">Residing Date</label>
                        <br />
                        <div className="date-container">
                          <input
                            value={residing_date}
                            onChange={(e) => setResiding_date(e.target.value)}
                            name="residing_date"
                            type="date"
                            className="inputtype bg-light mb-5 form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row ms-4 mb-4">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <img src={img} alt="" />
                      <div style={{ color: "#DE3500", marginTop: "10px" }}>
                        Please Wait ......
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div style={{ overflow: "auto" }}>
              <div style={{ float: "right" }}>
                <button
                  type="button"
                  className="tablinks mb-2 ms-2"
                  style={{ backgroundColor: "#BBBBBB" }}
                  onClick={prevStep}
                  disabled={isLoading}
                >
                  Previous
                </button>
                <button
                  className="tablinks mb-2 ms-2"
                  onClick={BgcFormSubmit}
                  disabled={isLoading}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {/*Sixth  Form ...... */}
        {currentStep === 6 && (
          <div style={{ height: "auto" }}>
            <div className="employement bg-light rounded-3 p-2 mx-auto mb-3 border border-opacity-25">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  PREVIOUS EMPLOYEMENT
                </span>{" "}
                DETAILS
              </div>
              <h4 className=" text-center mb-2">
                Please Remove All Password From The Documents
              </h4>
              <h5 className=" text-center mb-5">
                <a href="https://www.ilovepdf.com/unlock_pdf">
                  Click here to Remove Password
                </a>
              </h5>
              <div className="col-md-6 col-sm-12 mb-5">
                <label>Employment Choose</label>
                <br />
                <select
                  className="form-control mt-3"
                  name="choose_tenancy"
                  id="employment1"
                  onChange={hideEmployment}
                >
                  <option value="">Choose</option>
                  <option value="Fresher">Fresher</option>
                  <option value="Experience">Experience</option>
                </select>
              </div>
              {selectExperience ? (
                <div>
                  {/* {formValues.map((element, index) => ( */}
                  {Array.isArray(formValues) &&
                    formValues.map((element, index) => (
                      <div
                        className="form-inline"
                        key={index}
                        disabled={index !== formValues.length - 1}
                      >
                        <div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="organisation">Company Name</label>
                              <br />
                              <input
                                type="text"
                                value={element.previous_company_name || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_company_name"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="designation">Designation</label>
                              <br />
                              <input
                                type="text"
                                value={element.previous_designation || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_designation"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="hr_name">HR name</label>
                              <br />
                              <input
                                type="text"
                                value={element.hr_name || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="hr_name"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="Hr_number">
                                HR Contact Number
                              </label>
                              <br />

                              <input
                                type="number"
                                value={element.Hr_number || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="Hr_number"
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="Hr_email_id">HR Email Id</label>
                              <br />
                              <input
                                type="email"
                                value={element.Hr_email_id || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="Hr_email_id"
                                className=" bg-light mt-3 form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="Employee_id">
                                Employee Id
                              </label>
                              <br />
                              <input
                                type="text"
                                value={element.Employee_id || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="Employee_id"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_salary">
                                Annual CTC *
                              </label>
                              <br />

                              <input
                                type="number"
                                value={element.previous_salary || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_salary"
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="reason_of_leaving">
                                Reason of leaving
                              </label>
                              <br />
                              <input
                                type="text"
                                value={element.reason_of_leaving || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="reason_of_leaving"
                                className=" bg-light mt-3 form-control"
                                maxLength="255"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_manager">
                                Supervisor/Manager Name
                              </label>
                              <br />

                              <input
                                type="text"
                                value={element.previous_manager || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_manager"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_manager_number">
                                Supervisor/Manager contact number *
                              </label>
                              <br />
                              <input
                                type="number"
                                value={element.previous_manager_number || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_manager_number"
                                className=" bg-light mt-3 form-control"
                                maxLength="255"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_manager_email_id">
                                Supervisor/Manager Email id *
                              </label>
                              <br />

                              <input
                                type="email"
                                value={element.previous_manager_email_id || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_manager_email_id"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="from_previous_company">
                                From
                              </label>
                              <br />
                              <input
                                type="date"
                                value={element.from_previous_company || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="from_previous_company"
                                className=" bg-light mt-3 form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="to_previous_company">To</label>
                              <br />
                              <input
                                type="date"
                                value={element.to_previous_company || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="to_previous_company"
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="organisation">Location</label>
                              <br />
                              <input
                                type="text"
                                value={element.location || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="location"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="designation">
                                Work Responsibility
                              </label>
                              <br />
                              <input
                                type="text"
                                value={element.work_reponsibility || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="work_reponsibility"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="payslip">Pay Slip 1</label>
                              <br />
                              <input
                                type="file"
                                name="payslip"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "payslip")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="pay_slip2">Pay Slip 2</label>
                              <br />
                              <input
                                type="file"
                                name="pay_slip2"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "pay_slip2")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>

                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="pay_slip3">Pay Slip 3</label>
                              <br />
                              <input
                                type="file"
                                name="pay_slip3"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "pay_slip3")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="offer_latter">Offer Letter</label>
                              <br />
                              <input
                                type="file"
                                name="offer_letter"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "offer_letter")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="resignation_latter">
                                Resignation Letter
                              </label>
                              <br />
                              <input
                                type="file"
                                name="resignation"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "resignation")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="resignation_latter">
                                Experience Letter
                              </label>
                              <br />
                              <input
                                type="file"
                                name="experience_letter"
                                onChange={(e) =>
                                  handleFileChange1(
                                    e,
                                    index,
                                    "experience_letter"
                                  )
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row ms-4 mb-4">
                            {isLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: "1",
                                  flexDirection: "column",
                                }}
                              >
                                {" "}
                                <img src={img} alt="" />
                                <div
                                  style={{
                                    color: "#DE3500",
                                    marginTop: "10px",
                                  }}
                                >
                                  Please Wait ......
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="button-section">
                    <button
                      className="tablinks mb-2 ms-2"
                      style={{ backgroundColor: "#FF7700" }}
                      type="button"
                      onClick={EmploymentSubmit}
                      disabled={isLoading}
                    >
                      Add
                    </button>
                    <div style={{ overflow: "auto" }}>
                      <div style={{ float: "right" }}>
                        <button
                          type="submit"
                          className="tablinks mb-2 ms-2"
                          onClick={EmploymentSubmit1}
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
